<template>
  <v-row class="pa-4">
    <v-col class="pt-2" cols="12" md="3" sm="6">
      <v-form id="search_form">
        <MaterialCardMini color="secondary">
          <template v-slot:heading>
            <v-card-title class="pa-0 pl-2" style="font-size:.85em;">
              Search
            </v-card-title>
          </template>
          <v-card-text class="ma-0 pa-2">
            <v-select
              v-model="form_data.search_type"
              :items="search_types"
              label="Search Type"
              return-object
              hide-details
              class="mt-2 mb-0 custom-placeholder"
            >
            </v-select>

            <template v-if="form_data.search_type.value === 1">
              <v-card class="pa-0 mt-2" flat>
                <v-text-field
                  hide-details
                  v-model="form_data.search"
                  label="Global Search "
                  placeholder="DN, serial, algo, etc"
                  class="ma-0 pt-2 custom-placeholder"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form_data.subject_dn"
                  label="Subject"
                  class="mt-3 custom-placeholder"
                  placeholder="ex: Federal Bridge"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer class="pa-0 ma-0">
                    <v-select
                      :items="fqdn_attributes"
                      :value="0"
                      label="RDN"
                      return-object
                      class="pl-1 custom-placeholder"
                      v-model="form_data.subject_a"
                      hide-details
                      dense
                      outlined
                      style="width:85px;"
                      flat
                    >
                    </v-select>
                  </template>
                </v-text-field>

                <v-text-field
                  v-model="form_data.issuer_dn"
                  label="Issuer"
                  class="mt-3 custom-placeholder"
                  placeholder="ex: Common Policy"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-select
                      :items="fqdn_attributes"
                      :value="0"
                      label="RDN"
                      return-object
                      class="pl-1 custom-placeholder"
                      v-model="form_data.issuer_a"
                      hide-details
                      dense
                      outlined
                      style="width:85px;"
                      flat
                    >
                    </v-select>
                  </template>
                </v-text-field>

                <v-expansion-panels flat multiple>
                  <v-expansion-panel class="pa-0 ma-0">
                    <v-expansion-panel-header
                      class="pa-0 ma-0 pl-4 ma-n2"
                      style="font-size:1em;height:30px !important"
                      >Advanced</v-expansion-panel-header
                    >
                    <v-expansion-panel-content class="pa-0 ml-n6 mr-n6 mt-0">
                      <v-text-field
                        v-model="form_data.akid"
                        label="AKID"
                        class="mt-0 custom-placeholder"
                        placeholder="Authority Key ID"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-model="form_data.skid"
                        label="SKID"
                        class="mt-3 custom-placeholder"
                        placeholder="Subject Key ID"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-model="form_data.policy_oid"
                        label="Policy OID"
                        class="mt-3 custom-placeholder"
                        placeholder="ex: 2.16.840.1.101.3.2.1.3.13"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>

                      <v-select
                        v-model="form_data.pka"
                        label="Key Algorithm"
                        :items="key_algorithms"
                        :value="0"
                        hide-details
                        dense
                        placeholder="any"
                        outlined
                        class="mt-3 custom-placeholder"
                      >
                      </v-select>

                      <v-select
                        v-model="form_data.pk_bit_size"
                        label="Key Size"
                        :items="key_sizes"
                        hide-details
                        dense
                        placeholder="any"
                        outlined
                        class="mt-3 custom-placeholder"
                      >
                      </v-select>

                      <v-text-field
                        v-model="form_data.thumbprint"
                        label="Thumbprint"
                        class="mt-3 custom-placeholder"
                        placeholder="SHA-1 or SHA-2"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </template>
            <template v-else-if="form_data.search_type.value === 2">
              <v-card class="pa-0 mt-2" flat>
                <v-text-field
                  v-model="form_data.issuer_dn"
                  label="Issuer"
                  class="mt-3 custom-placeholder"
                  placeholder="ex: Common Policy"
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-select
                      :items="fqdn_attributes"
                      :value="0"
                      label="RDN"
                      return-object
                      class="pl-1 custom-placeholder"
                      v-model="form_data.issuer_a"
                      hide-details
                      dense
                      outlined
                      style="width:85px;"
                      flat
                    >
                    </v-select>
                  </template>
                </v-text-field>

                <v-text-field
                  v-model="form_data.url"
                  label="URL"
                  class="mt-3 custom-placeholder"
                  placeholder="ex: fpki.gov"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-card>
            </template>
            <template v-else-if="form_data.search_type.value === 3">
              <v-card class="pa-0 mt-2" flat>
                <v-text-field
                  v-model="form_data.url"
                  label="URL"
                  class="mt-3 custom-placeholder"
                  placeholder="ex: fpki.gov"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-card>
            </template>
          </v-card-text>
        </MaterialCardMini>

        <MaterialCardMini>
          <template v-slot:heading>
            <v-card-title class="pa-0 pl-2" style="font-size:.85em;">
              Include
            </v-card-title>
          </template>
          <v-card-text class="ma-0 pa-0">
            <v-card class="pa-2 mt-2">
              <template v-if="form_data.search_type.value === 1">
                <v-checkbox
                  label="EEs"
                  name="settings"
                  v-model="form_data.ee"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Expired"
                  name="settings"
                  v-model="form_data.expired"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Revoked"
                  name="settings"
                  v-model="form_data.revoked"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Not Hosted"
                  name="settings"
                  v-model="form_data.not_hosted"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Old Versions"
                  name="settings"
                  v-model="form_data.old"
                  dense
                  hide-details
                ></v-checkbox>
              </template>
              <template v-else-if="form_data.search_type.value === 2">
                <v-checkbox
                  label="Expired"
                  name="settings"
                  v-model="form_data.expired"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Not Hosted"
                  name="settings"
                  v-model="form_data.not_hosted"
                  dense
                  hide-details
                ></v-checkbox>
              </template>
              <template v-else-if="form_data.search_type.value === 3">
                <v-checkbox
                  label="Public"
                  name="settings"
                  v-model="form_data.include_public"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Private"
                  name="settings"
                  v-model="form_data.include_private"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="AIA"
                  name="settings"
                  v-model="form_data.include_types"
                  value="aia"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="SIA"
                  name="settings"
                  v-model="form_data.include_types"
                  value="sia"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="CRL"
                  name="settings"
                  v-model="form_data.include_types"
                  value="crl"
                  dense
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="OCSP"
                  name="settings"
                  v-model="form_data.include_types"
                  value="ocsp"
                  dense
                  hide-details
                ></v-checkbox>
              </template>
            </v-card>
          </v-card-text>
        </MaterialCardMini>

        <GroupFilter
          class="mt-2"
          @filter-group-rules="filter_group_rules"
        ></GroupFilter>
      </v-form>
    </v-col>

    <v-col class="pt-4" cols="12" md="9" sm="6">
      <v-card
        v-if="search_loading"
        style="width:100%;background-color:transparent;border:0;"
        flat
      >
        <v-overlay absolute z-index="9001">
          <LoadingSVG />
        </v-overlay>
      </v-card>

      <v-card
        v-else
        style="width:100%;background-color:transparent;border:0;"
        flat
      >
        <SearchCertificates
          v-if="form_data.search_type.value === 1"
          :data_url="data_url"
          :search="form_data.search"
        />

        <template v-if="form_data.search_type.value === 2">
          crls <br />
          {{ data_url }} {{ form_data.search }}
        </template>
        <SearchUris
          v-if="form_data.search_type.value === 3"
          :data_url="data_url"
          :search="form_data.search"
        ></SearchUris>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import GroupFilter from "../components/groups/Filter";
import LoadingSVG from "../components/LoadingSVG";
import SearchCertificates from "../components/search/Certificates";
import SearchUris from "../components/search/Uris";
import MaterialCardMini from "../components/charts/MaterialCardMini";
export default {
  name: "Search",
  components: {
    MaterialCardMini,
    SearchUris,
    SearchCertificates,
    LoadingSVG,
    GroupFilter
  },
  data: function() {
    return {
      display_type: 0,
      search: null,
      data_url: null,
      isActive: false,
      local_search_loading: true,
      local_search_result: [],
      local_search_result_length: [],
      public_search_loading: true,
      public_search_result: [],
      public_search_result_length: [],
      form_data: {
        search: "",
        search_type: {
          value: 1,
          text: "Certificates",
          api: "certificates",
          params: {
            str: [
              "akid",
              "skid",
              "policy_oid",
              "pka",
              "pk_bit_size",
              "thumbprint"
            ],
            include: ["ee", "expired", "revoked", "not_hosted", "old"],
            computed: ["issuer", "subject"]
          }
        },
        ee: false,
        expired: false,
        revoked: true,
        not_hosted: true,
        old: false,
        subject_dn: null,
        subject_a: null,
        issuer_dn: null,
        issuer_a: null,
        akid: null,
        skid: null,
        policy_oid: null,
        pka: null,
        pk_bit_size: null,
        thumbprint: null,
        groups: [],
        export_p7c: false,
        export_pem: false,
        filter_group_rules: [],
        include_public: true,
        include_private: true,
        include_types: ["crl", "sia", "aia", "ocsp"]
      },
      //search_params: ['search_type', 'ee', 'expired', 'revoked', 'nothosted', 'old'],
      search_types: [
        {
          value: 1,
          text: "Certificates",
          api: "certificates",
          params: {
            str: [
              "akid",
              "skid",
              "policy_oid",
              "pka",
              "pk_bit_size",
              "thumbprint"
            ],
            include: ["ee", "expired", "revoked", "not_hosted", "old"],
            computed: ["issuer", "subject"]
          }
        },
        {
          value: 3,
          text: "URLs",
          api: "uris",
          params: {
            str: ["url"],
            include: ["include_public", "include_private", "include_types"],
            computed: []
          }
        },
        {
          value: 2,
          text: "CRLs",
          api: "crls",
          params: {
            str: ["url"],
            include: ["expired", "not_hosted"],
            custom: ["issuer"]
          },
          disabled: true
        },
        {
          value: 4,
          text: "CRL Content",
          params: {
            str: [],
            bool: [],
            custom: []
          },
          disabled: true
        },
        {
          value: 5,
          text: "P7C Content",
          params: {
            str: [],
            bool: [],
            custom: []
          },
          disabled: true
        },
        {
          value: 6,
          text: "URL Availability",
          params: {
            str: [],
            bool: [],
            custom: []
          },
          disabled: true
        },
        {
          value: 7,
          text: "OCSP Certificates",
          params: {
            str: [],
            bool: [],
            custom: []
          },
          disabled: true
        },
        {
          value: 8,
          text: "DNS Results",
          params: {
            str: [],
            bool: [],
            custom: []
          },
          disabled: true
        }
      ],
      fqdn_attributes: [{ value: 0, text: "Any" }],
      key_algorithms: [],
      key_sizes: []
    };
  },
  watch: {
    form_data: {
      handler: function() {
        let self = this;
        this.public_search_loading = true;
        setTimeout(function() {
          self.doSearch(1);
        }, 500);
      },
      deep: true
    }
  },
  created: function() {
    this.doSearch(1);
  },
  computed: {
    search_loading: function() {
      return this.public_search_loading;
    },
    search_result: function() {
      return this.local_search_result.concat(this.public_search_result);
    },
    issuer: function() {
      if (!this.form_data.issuer_dn) {
        return null;
      } else if (this.form_data.issuer_dn && this.form_data.issuer_a) {
        return this.form_data.issuer_dn + "," + this.form_data.issuer_a.text;
      } else {
        return this.form_data.issuer_dn;
      }
    },
    subject: function() {
      if (!this.form_data.subject_dn) {
        return null;
      } else if (this.form_data.subject_dn && this.form_data.subject_a) {
        if (this.form_data.subject_a.text === "Any") {
          return this.form_data.subject_dn;
        } else {
          return (
            this.form_data.subject_dn + "," + this.form_data.subject_a.text
          );
        }
      } else {
        return this.form_data.subject_dn;
      }
    }
  },
  methods: {
    filter_group_rules: function(settings) {
      this.form_data.filter_group_rules = settings;
    },
    doSearch(page) {
      this.public_search_loading = true;

      let query_params = ["page=" + page];
      let params = this.form_data.search_type.params;

      for (let c in params.computed) {
        if (this[params.computed[c]]) {
          query_params.push(
            params.computed[c] + "=" + this[params.computed[c]]
          );
        }
      }
      for (let s in params.str) {
        if (this.form_data[params.str[s]]) {
          query_params.push(
            params.str[s] + "=" + this.form_data[params.str[s]]
          );
        }
      }
      for (let i in params.include) {
        query_params.push(
          params.include[i] + "=" + this.form_data[params.include[i]]
        );
      }
      if (typeof this.form_data.filter_group_rules.ca !== "undefined") {
        if (
          this.form_data.filter_group_rules.ca.length > 0 ||
          this.form_data.filter_group_rules.path.length > 0
        ) {
          query_params.push(
            "filter_group_rules=" +
              JSON.stringify(this.form_data.filter_group_rules)
          );
        }
      }

      var url = this.form_data.search_type.api + "/?" + query_params.join("&");
      this.public_search_result = [];
      this.data_url = url;
      this.loading = false;
      this.public_search_loading = false;
    }
  }
};
</script>

<style scoped></style>
